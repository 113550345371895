import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore/lite";
import moment from "moment";
try {
    initializeApp({
        apiKey: "AIzaSyCOCUFlJtqC87Ikmo--VyTqeyl0eTARfjk",
        authDomain: "oilservsupplierportal.firebaseapp.com",
        projectId: "oilservsupplierportal",
        storageBucket: "oilservsupplierportal.appspot.com",
        messagingSenderId: "650916647662",
        appId: "1:650916647662:web:f43444a5d037d3acf7d9ff",
        measurementId: "G-L161ENWBTQ",
    })
} catch (error) {
    throw new Error(error)
}

export const getLoggedInUser = function() {
    return new Promise((resolve, reject) => {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                const supplierDetails = await getCurrentUserDetails(user.uid);
                const userGeoInfo = await fetch("https://ipwho.is/").then((res) => res.json());

                resolve({ user, supplierDetails, userGeoInfo });
            } else {
                reject(null);
            }
        });
    });
};

async function getCurrentUserDetails(uid) {
    const db = getFirestore();
    try {
        const UserRef = doc(db, "Users", uid);
        const UserData = await getDoc(UserRef);
        return await UserData.data();
    } catch (e) {
        console.log(e);
        throw new Error(e);
    }
}

export const AllCurrencies = [
    "AED",
    "ARS",
    "AUD",
    "BGN",
    "BHD",
    "BND",
    "BRL",
    "BWP",
    "CAD",
    "CHF",
    "CLP",
    "CNY",
    "COP",
    "CZK",
    "DKK",
    "DZD",
    "EGP",
    "EUR",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "IQD",
    "IRR",
    "ISK",
    "JOD",
    "JPY",
    "KRW",
    "KWD",
    "KZT",
    "LKR",
    "LTL",
    "LVL",
    "LYD",
    "MAD",
    "MUR",
    "MXN",
    "MYR",
    "NGN",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PHP",
    "PKR",
    "PLN",
    "QAR",
    "RON",
    "RUB",
    "SAR",
    "SDG",
    "SEK",
    "SGD",
    "THB",
    "TND",
    "TRY",
    "TTD",
    "TWD",
    "USD",
    "VEF",
    "ZAR",
];
export const AllEntities = [
    "Advanced Engineering & Technology FZE",
    "OiLSERV Oilfield Services Limited",
    "Oil Technology for Equipment and Oilfield Services LLC",
    "OiLSERV Oilfield Services (BVI) LTD (Algeria)",
    "OiLSERV Saudi Arabia LTD",
    "Golden Algar Technical Oil Services",
    "OiLSERV Oilfield Services (BVI) LTD – Tunisia Branch",
    "OiLSERV Kuwait Limited for Petroleum Services",
    "OiLSERV FZCO",
    "OiLSERV Oilfield Services (BVI) LTD",
    "OiLSERV Oilfield Services (BVI) LTD – Abu Dhabi",
    "OiLSERV Oilfield Services (BVI) LTD – Rep Office",
];


export class Invoice {
    constructor(obj){
        this.ip = obj.ip;
        this.userUID = obj.uid;
        this.invoiceStatus = obj.invoiceStatus;
        this.addedOn = moment(obj.addedOn).local().format();
        this.invoiceNumber = obj.invoiceNumber;
        this.invoiceAmount = obj.invoiceAmount;
        this.invoiceCurrency = obj.invoiceCurrency;
        this.invoiceDate = moment(obj.invoiceDate).local().format();
        this.referenceType = obj.referenceType;
        this.referenceNumber = obj.referenceNumber;
        this.attachments = obj.attachments;
        this.entityName = obj.entityName;
    }
}
