<template>
  <!-- <div class="preloader" v-if="preloaderStatus"></div> -->
  <div class="preloader" v-if="preloaderStatus">
        <div class="preloaderLoading"></div>
    </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  name: 'Preloader',
  setup() {
    const store = useStore();
    const preloaderStatus = computed(() => store.getters.getPreloaderStatus);
    return { preloaderStatus };
  }
};
</script>

<style>

.preloader {
    background-color: #fff;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1059;
}

.preloaderLoading {
    animation: 1.5s linear 0s normal none infinite running preloaderLoading;
    background: #fed37f;
    border-radius: 50px;
    height: 50px;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
    position: absolute;
    top: 50%;
    width: 50px
}

.preloaderLoading::after {
    animation: 1.5s linear 0s normal none infinite running preloaderLoading_after;
    border-color: #85d6de transparent;
    border-radius: 80px;
    border-style: solid;
    border-width: 10px;
    content: "";
    height: 80px;
    left: -15px;
    position: absolute;
    top: -15px;
    width: 80px
}

@keyframes preloaderLoading {
	0% {
		transform: rotate(0);
	}

	50% {
		background: #f15922;
		transform: rotate(180deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>