<template>
  <div class="home">
    <div class="row" v-if="user">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title orange">Supplier Details</h5>
            <hr />
            <p><b>Supplier ID</b>: {{ vendor.vendorID }}</p>
            <p><b>Supplier Name</b>: {{ vendor.vendorName }}</p>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title orange">Your Details</h5>
            <hr />
            <p><b>Display Name</b>: {{ user.displayName }}</p>
            <p><b>Email</b>: {{ user.email }}</p>
            <p><b>IP Address</b>: {{ geoInfo.ip }}</p>
          </div>
        </div>
        <p>Please note we will record you login details for security reasons</p>
      </div>
    </div>
    <hr />
    <div v-if="user">
      <h4 class="orange">Introduction to the vendor portal</h4>
      <p>
        Dear {{ user.displayName }},<br />OiLSERV as part of the initiative to streamline the
        accounts payable process, has introduced a portal for submission of
        invoices to properly track the same and to avoid email communications.
        Each Vendor will be registered on the portal using email addresses and
        passwords. After registration, all registered Vendors will be able to
        access this portal, submit invoices online and track the status of all
        submitted invoices.
      </p>
      <h4 class="orange">Registration process:</h4>
      <p>
        In order to get you registered, please send an email to
        payables@oilserv.com with complete Vendor name and email address(es)
        which are going to upload invoices to OiLSERV portal. After entry into
        the registration the email address(es) will receive an email
        notification to verify the same and set a password. <br />
        <b>
          Note : Pls ensure that the domain
          “oilservsupplierportal.firebaseapp.com” is not blocked by your
          internal firewall to receive the below email. Once you click the link,
          screen will open in your internet browser to set a password.</b
        >
        <br />
        Once email address is confirmed and the password is set, you will now be
        able to upload invoices to the portal.
      </p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "Home",
  setup() {
    const store = useStore();
    const user = computed(() => store.getters.getCurrentUser);
    const vendor = computed(() => store.getters.getCurrentUserVendor);
    const geoInfo = computed(() => store.getters.getCurrentUserGeoInfo);
    return { user, vendor, geoInfo };
  },
};
</script>
