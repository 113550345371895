import { createStore } from "vuex";
import { getLoggedInUser } from "@/config.js";
export default createStore({
    state: {
        preloaderStatus: true,
        currentUser: null,
        currentUserVendor: null,
        currentUserGeoInfo: null,
    },
    mutations: {
        setPreloaderStatus(state, status) {
            state.preloaderStatus = status;
        },
        setCurrentUser(state, status) {
            state.currentUser = status;
        },
        setCurrentUserVendor(state, status) {
            state.currentUserVendor = status;
        },
        setCurrentUserGeoInfo(state, status) {
            state.currentUserGeoInfo = status;
        },
    },
    actions: {
        async initiateApp(context) {
            context.commit("setPreloaderStatus", true);

            try {
                const { user, supplierDetails, userGeoInfo } = await getLoggedInUser();
                context.commit("setCurrentUser", user);
                context.commit("setCurrentUserVendor", supplierDetails);
                context.commit("setCurrentUserGeoInfo", userGeoInfo);
                return user;
            } catch {
                return null;
            }
        },
    },
    getters: {
        getPreloaderStatus: (state) => state.preloaderStatus,
        getCurrentUser: (state) => state.currentUser,
        getCurrentUserVendor: (state) => state.currentUserVendor,
        getCurrentUserGeoInfo: (state) => state.currentUserGeoInfo,
    },
});
