<template>
  <Navbar></Navbar>
  <Preloader></Preloader>
  <div class="container-fluid mt-3">
    <div class="row">
      <div class="col">
        <h4 class="cyan">OiLSERV Supplier Invoice Management</h4>
      </div>
      <div class="col text-end">
        <div class="btn-group btn-group-sm" v-if="user">
          <button type="button" class="btn btn-outline-warning" @click="logout">
            <i class="bi bi-door-open-fill"></i> Sign Out
          </button>
        </div>
      </div>
    </div>
    <hr />
    <div v-if="error" class="alert alert-danger" role="alert">
      {{ error }}
    </div>
    <div v-else class="m-3">
      <Suspense>
        <template #default>
          <router-view v-slot="{ Component }">
            <transition name="route" mode="out-in">
              <component :is="Component"></component>
            </transition>
          </router-view>
        </template>
        <template #fallback>
          <span>Loading...</span>
        </template>
      </Suspense>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { onBeforeMount, onErrorCaptured, computed, ref } from "vue";
import Navbar from "@/components/common/Navbar.vue";
import Preloader from "@/components/common/Preloader.vue";
import { getAuth, signOut } from "firebase/auth";
export default {
  name: "App",
  components: {
    Navbar,
    Preloader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const user = computed(() => store.getters.getCurrentUser);
    const error = ref(null);
    onBeforeMount(async () => {
      try {
        let user = await store.dispatch("initiateApp");
        if (user) {
          router.push({ name: "Home" });
        } else {
          router.push({ name: "Login" });
        }
        store.commit("setPreloaderStatus", false);
      } catch (error) {
        throw new Error(error);
      }
    });
    onErrorCaptured((e) => {
      store.commit("setPreloaderStatus", false);
      error.value = e;
      return false;
    });

    const logout = () => {
      const auth = getAuth();
      signOut(auth).then(() => {
        router.push({ name: "Login" });
      });
    };

    return { error, user, logout };
  },
};
</script>

<style>
#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
