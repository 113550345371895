<template>
    <nav class="navbar navbar-expand-sm bg-white border-bottom shadow-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="https://oilserv.sharepoint.com/SitePages/Sky.aspx" id="navbarSupportedContent" v-if="!preloaderStatus">
                <img src="@/assets/logo.svg" height="30" />
            </a>
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                    <path
                        fill-rule="evenodd"
                        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                    />
                </svg>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent" v-if="!preloaderStatus">
                <ul class="navbar-nav ms-auto mb-2 mb-sm-0">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/" class-active="active">Home</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/newInvoice" class-active="active">New Invoice</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/myInvoices" class-active="active">My Invoices</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
    name: "Navbar",
    setup() {
        const Role = "Admin";
        const store = useStore();
        const preloaderStatus = computed(() => store.getters.getPreloaderStatus);
        return { preloaderStatus, Role };
    },
};
</script>

<style>
.dropdown-header {
    color: #f15922 !important;
    font-size: 1.1rem !important;
    margin-left: -7px;
}

#nav a.router-link-exact-active {
    color: #f15922;
}

/* route transitions */
.route-enter-from {
    opacity: 0;
}
.route-enter-active {
    transition: all 0.25s ease-out;
}
.route-leave-to {
    opacity: 0;
}
.route-leave-active {
    transition: all 0.25s ease-in;
}
</style>
