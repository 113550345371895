import { createRouter, createWebHashHistory } from 'vue-router'
import store from "@/store"
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/newInvoice',
    name: 'NewInvoice',
    meta: {requiresAuth: true},
    component: () => import(/* webpackChunkName: "invoice" */ '../components/invoice/NewInvoice.vue')
  },
  {
    path: '/myInvoices',
    name: 'MyInvoices',
    meta: {requiresAuth: true},
    component: () => import(/* webpackChunkName: "invoice" */ '../components/invoice/MyInvoices.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "common" */ '../components/common/Login.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory (process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !store.state.currentUser) next('Login');
  else next();
});

export default router
